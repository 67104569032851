import React from "react"
import { Link, graphql } from "gatsby"
import {FaArrowAltCircleLeft} from 'react-icons/fa';
import {
    MdLocationOn,
    MdPhone,
    MdEmail,
    MdPublic
  } from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const ProjectDetails = ({data: { markdownRemark,image1,bg_2,work_1,work_2,work_3,work_4 }}) => {


    let imgProd = bg_2.childImageSharp.fluid.src;
    let idProd = markdownRemark.frontmatter.code;
    if(idProd === 1){
        imgProd = work_1.childImageSharp.fluid.src

    }else if(idProd === 2){
        imgProd = work_2.childImageSharp.fluid.src

    }else if(idProd === 3){
        imgProd = work_3.childImageSharp.fluid.src

    }else if(idProd === 4){
        imgProd = work_4.childImageSharp.fluid.src

    }
  
    const titleType = markdownRemark.frontmatter.type === "project" ? "Proyecto" : "Producto" 
    const titleTypes = markdownRemark.frontmatter.type === "project" ? "Proyectos" : "Productos" 
    

    return (
        <Layout changeHeader={2}>
            <SEO title={`Detalles de ${titleType}`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#projects-section" className="icon ml-5" title="Regresar">
                            <FaArrowAltCircleLeft
                                color="#0075c7"
                                size="3em"
                            /> 
                        </Link>
                        
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Detalles de {markdownRemark.frontmatter.title} </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            
                                <Link  className="" to="/#blog-section">{titleTypes}</Link> > </span> <span>{markdownRemark.frontmatter.title}</span>
                            </p>
                    </div>
                    </div>
                </div>
            </section>

            { titleType === "Proyecto" ? 
                        <section className="p-2">
                        
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                                        <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                                        <p dangerouslySetInnerHTML={{ __html: markdownRemark.html }}></p>
                                    </div>

                                    <div className="col-md-5 col-12">
                                        <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                            <h3>Proyectos relacionados</h3>
                                            <div className="block-21 mb-12 d-flex">
                                                <Link to="/proyecto/1" className="blog-img mr-4" style={{backgroundImage: `url(${work_1.childImageSharp.fluid.src})`}}></Link>
                                                <div className="text">
                                                <h3 className="heading"><Link to="/proyecto/1">Proyecto 1, Lorem ipsum dolor sit amet consectetur adipisicing elit.</Link></h3>
                                                <div className="meta">
                                                    <div><span className="icon-calendar"></span> Feb. 07, 2019</div>
                                                    <div><span className="icon-person"></span> Dave Lewis</div>
                                                    <div><span className="icon-chat"></span> 19</div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="block-21 mb-12 d-flex">
                                                <Link to="/proyecto/2" className="blog-img mr-4" style={{backgroundImage: `url(${work_2.childImageSharp.fluid.src})`}}></Link>
                                                <div className="text">
                                                <h3 className="heading"><Link to="/proyecto/2">Producto 2, Lorem ipsum dolor sit amet consectetur adipisicing elit.</Link></h3>
                                                <div className="meta">
                                                    <div><span className="icon-calendar"></span> Feb. 07, 2019</div>
                                                    <div><span className="icon-person"></span> Dave Lewis</div>
                                                    <div><span className="icon-chat"></span> 19</div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="block-21 mb-12 d-flex">
                                                <Link to="/proyecto/3" className="blog-img mr-4" style={{backgroundImage: `url(${work_3.childImageSharp.fluid.src})`}}></Link>
                                                <div className="text">
                                                    <h3 className="heading"><Link to="/proyecto/3">Producto 3, Lorem ipsum dolor sit amet consectetur adipisicing elit.</Link></h3>
                                                    <div className="meta">
                                                        <div><span className="icon-calendar"></span> Feb. 07, 2019</div>
                                                        <div><span className="icon-person"></span> Dave Lewis</div>
                                                        <div><span className="icon-chat"></span> 19</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                
                :
                        <section className="p-2">
                        
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                            <p dangerouslySetInnerHTML={{ __html: markdownRemark.html }}></p>   
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                                        <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                                    </div>

                                    <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >

                                        <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
              <h2 className="mb-4">Pasos del producto</h2>
            </div>
          </div>
          <div className="row d-flex contact-info mb-5">
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdLocationOn color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Dirección</h3>
                <p>Avda. 2 con calle 4 casa Nro. 39 Urb. Baraure 2 Sector 7, Araure Edo. Portuguesa</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdPhone color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Número de Contacto</h3>
          <p>
            <a href="tel://4245149117">+ 58 424-5149117 </a><br/>
            <a href="tel://4143559214">+ 58 414-3559214 </a>
          </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdEmail color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Correo Eléctronico</h3>
                <p><a href="mailto:contacto@metalinks.com.ve">contacto@metalinks.com.ve</a></p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdPublic color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Sitio web</h3>
                <p><a href="/#">metalinks.com.ve</a></p>
              </div>
            </div>
          </div>
          
        </div>
      </section>


                                    </div>

                                </div>
                            </div>
                        </section>
            }

           
            
        </Layout>
    )
}

export default ProjectDetails

export const pageQuery = graphql`
  query FacByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        code
        type
      }
    },

    allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              path
              image
            }
            html
          }
        }
      }
      bg_2: file(relativePath: {eq: "bg_2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            src
          }
        }
      }
    image1: file(relativePath: { eq: "bg_4.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    work_1: file(relativePath: { eq: "work-1.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    work_2: file(relativePath: { eq: "work-2.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    work_3: file(relativePath: { eq: "work-3.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    work_4: file(relativePath: { eq: "work-4.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    

  }
`